html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

.App {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;

  display: flex;
  flex-wrap: wrap;
}

.user,
.entity {
  box-sizing: border-box;
  width: 50vw;
}

.user .id {
  color: green;
}

.touch-layer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  cursor: pointer;
}

.id {
  position: absolute;
  text-transform: uppercase;
  font-family: monospace;
  background: #ccc;
  color: white;
  padding: 3px;
}

.status {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.status.connected {
  background: green;
}

.status.disconnected {
  background: orange;
}
